import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import { GridCol, GridRow } from '../components/grid'
import colors from '../styles/colors'
import { mqFrom } from '../styles/responsive'
import { applyTextStyle } from '../styles/typography'

const IndexPage: React.FC = () => {
  return (
    <>
      <PageTitle>Hello</PageTitle>
      <GridRow>
        <BlueCol s={1} m={2} l={4}>
          1/4 of screen
        </BlueCol>
        <RedCol s={1} m={2} l={4}>
          1/4 of screen
        </RedCol>
        <GreenCol s={1} m={2} l={4}>
          1/4 of screen
        </GreenCol>
        <YellowCol s={1} m={2} l={4}>
          1/4 of screen
        </YellowCol>
      </GridRow>
      <GridRow>
        {[...Array(8)].map(() => (
          <>
            <BlueCol s={1} m={1} l={1}>
              1/16 of screen or 1/8 or 1/4
            </BlueCol>
            <RedCol s={1} m={1} l={1}>
              1/16 of screen or 1/8 or 1/4
            </RedCol>
          </>
        ))}
      </GridRow>
      <GridRow>
        <GridCol l={1}></GridCol>
        <GridCol l={15}>
          <MyBox>Component</MyBox>
        </GridCol>
      </GridRow>
      <Button type="small">Read more hehehe button large</Button>
      <Button type="medium">Read more hehehe button large</Button>
      <Button type="large">Read more hehehe button large</Button>
    </>
  )
}

const BlueCol = styled(GridCol)`
  background-color: blue;
  height: 200px;
`

const RedCol = styled(GridCol)`
  background-color: red;
  height: 200px;
`

const GreenCol = styled(GridCol)`
  background-color: green;
  height: 200px;
`

const YellowCol = styled(GridCol)`
  background-color: yellow;
  height: 200px;
`

const PageTitle = styled.div`
  ${applyTextStyle('normal')};
  padding: 16px;
  color: ${colors.lightBlue};
  margin: 50px;
  ${mqFrom.S`
    color: ${colors.white};
  `}
  ${mqFrom.M`
    color: ${colors.brown};
  `}
`

const MyBox = styled.div`
  width: 100%;
  background: red;
`

export default IndexPage
